.mainContainer {
  width: 60%;
  margin: 0 auto;
  background: white;
    border-radius: 20px;
    box-shadow: 7px 3px 21px #0000003d;
}

.mainContainer table {
  width: 100%;
  border-collapse: collapse;
}

.mainContainer tr td,
.mainContainer tr th {
  padding: 10px;
}

.mainContainer table thead th {
  background-color: #007bffab;
  color: #fff;
}

.cp {
    cursor: pointer;
}